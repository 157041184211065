import React, { useState, useEffect, useContext ,useRef} from 'react';
import '../Company/CompanyAccordion.css';
import './ProjectDetails.css';
import plusIcon from '../../Assets/Images/plusIcon.png';
import { Link, useParams, useNavigate } from "react-router-dom";
import ProjectsService from '../../Services/ProjectsService';
import Modal from 'react-bootstrap/Modal';
import MembersModal from '../Company/MembersModal';
//import CreateMember from '../Company/MembersModal';
import ToasterComponent from '../../Assets/Utils/ToasterComponent';
import OtpinputModal, { OtpContext } from '../Company/OtpInputModal';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import OtpInputModal from '../Company/OtpInputModal';
import { render } from '@testing-library/react';
import UploadProperties from './UploadProperties';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirm } from "./ConfirmationPopup";
import { async } from 'q';
import Cookies from 'universal-cookie';

import AppCache from '../../Cache/AppCache';
import ViewCredentialPopup from '../Company/ViewCredentialPopup';
import AddAPIDialog from '../Company/AddAPIDialog';
import UserService from '../../Services/UserService';

import InformationDialog from '../Company/InformationDialog';
import ProdAccessInfoDialog from './ProdAccessInfoDialog';
//import 'bootstrap/dist/css/bootstrap.min.css'; 
import SRQService from '../../Services/SRQService';
import ServiceRequestModel from '../ServiceRequest/ServiceRequestModel';
import Spinner from 'react-bootstrap/Spinner';
const propInputBottomBorder = {
  border: '0',
  outline: '0',
  borderBottom: '2px solid #1890ff',
  backgroundColor: 'transparent',
  color: '#2d32aa'
 

};
const overlayStyle ={
  fontSize: '15px',
lineHeight: '19px',
fontWeight: 'normal',
wordBreak: 'break-all',
tableLayout:'fixed',
width:'100%'
};

const urlOverflow ={
  textOverflow: 'ellipsis',
  whiteSpace:'nowrap',
  overflow: 'hidden',
};

const popoverUrlStyle ={
  maxWidth: '35%',
  minHeight: '6%',
  fontSize: 'large',
  border: '1px solid #2d32aa',
  borderRadius: 0
};

var otpInputModelOpenendForAction = '';
var credentialRecord;
var credentialURL = '';
var tokenEndpointURL = '';
const ProjectDetails = () => {
  const [currentPopover, setCurrentPopover] = useState(null);

const renderUrlRow = (pMode, endPoint) => {
  return(
    <tr>
      <th scope="row" width='13%'>{pMode} endpoint:</th>
      <td  style={urlOverflow} width='25%'>{endPoint}</td>
      <td width='5%'>
      <a
         className="bi bi-clipboard "
          href="javascript:;"
         onClick={()=>copyToClipboard(endPoint)}
         title='Copy URL'
        >
          <span className="icon-copy-two" />
        </a>

      </td>
    </tr>
  );
}

const popover = (projectMode, testURL, prodURL, sandBoxURL) => (
<Popover id={`popover-positioned-bottom`} style={popoverUrlStyle} >  
<Popover.Body className="m-1 p-0 ">  

<table className="table table-borderless m-0 p-0 " style={overlayStyle}>
{
projectMode==='Sandbox'?renderUrlRow('Sandbox',sandBoxURL)
:<>
   {renderUrlRow('Test',testURL)}
   {renderUrlRow('Prod',prodURL)}
</>
}
</table>


</Popover.Body>  
</Popover>
  );

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard:', text);
    } catch (error) {
      console.log('Error copying to clipboard:', error);
    }
  };

    //convert date from iso to current for credentials
    const formatDateForCredentials = (dateString) => {
      const date = new Date(dateString);
    
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
    
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
    
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

  const saveChangesButtonRef = useRef(null);
  var _dashboardAPICounterForCredStatus = 0;
  var _maxDashboardAPICounterForCredStatus = 3;
  var _timeoutDashboardAPICredCreation = 3000;
  var _typedCredentials;
  var _startedCalls = 0;
  var credEmptyStyleArrow = 'style="display:none"';
  var credEmptyStyle = 'style="display:none"';
  var _creatingCredMessage =
    "Taking time, please try to view credential after sometime...";
  // var relativeUrl = Nets.Projects.ListCredentials.resendObject.relativeUrl;
  //     var authorizationUrl =
  //       "/" + Nets.Projects.WebService.basicDeTokenizeUrl(relativeUrl);

  const otpContextValue = React.useContext(OtpContext);
  const { companyId, projectId } = useParams()
  const navigate = useNavigate();

  const [projectsDashboard, setProjectsDashboard] = useState([]);


  const [spinnerActiveIndex, setSpinnerActiveIndex] = useState(-1);
  const [spinnerActiveCreateCredential, setSpinnerActiveCreateCredential] = useState(false);


  const [strongConfirmationCode, setStrongConfirmationCode] = useState([]);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [apiDialogOpen, setAPIDilogOpen] = useState(false);
  const [infoModelOpen, setInfoModelOpen] = useState(false);
  const [prodAccessInfoModelOpen, setProdAccessInfoModelOpen] = useState(false);
  const [addMember, setAddMember] = useState([]);
  const [OtpModalOpen, setOtpModalOpen] = useState(false);
  const [viewCredentialPopup, setViewCredentialPopup] = useState(false);
  const [scrollToApiAndPropertyView, setScrollToApiAndPropertyView] = useState(false);
  const [scrollToMemberView, setScrollToMemberView] = useState(false);

  const [OtpDataFromModal, setOtpDataFromModal] = useState([]);
  const [scrollToCredentialView, setScrollToCredentialView] = useState(false);

  const [editId, setEditID] = useState(-1);
  const [propValue, setPropValue] = useState('');
  const [propEnv, setPropEnv] = useState('');

  const [projectProp, setProjectProp] = useState([]);

  const [editedProperties, setEditedProperties] = useState([]);

  const [apiSubscriptionsIds, setApiSubscriptionsIds] = useState([]);

  const [uploadPropertiesOpenModal, setUploadPropertiesOpenModal] = useState(false);

  const [saveDiscardDisabled, setSaveDiscardDisabled] = useState(true);

  const [computedProperties, setComputedProperties] = useState([]);

  const [computedPropertiesRes, setComputedPropertiesRes] = useState([]);

  const [apiSubscriptionsRemovedId, setApiSubscriptionsRemovedId] = useState([]);

  const [viewCredentialsDataForOTP, setViewCredentialsDataForOTP] = useState({});

  const [viewCredentialId, setViewCredentialId] = useState(null);
  const [projectEnvironment, setProjectEnvironment] = useState('');

  const [userDetails, setUserDetails] = useState({});
  const [isRmvAllAccessConfirmHighlighted, setRmvAllAccessConfirmHighlighted] =  useState(false);
  const [viewCredentialPopupData, setViewCredentialPopupData] = useState(
    {
      key: "",
      secret: ""
    }
  );
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [myVerticallyCenteredModal, setMyVerticallyCenteredModal] = useState(false);
  const [removeAllAccessConfirmationModal, setRemoveAllAccessConfirmationModal] = useState(false);
  const [removeAllAccessConfirmation, setRemoveAllAccessConfirmation] = useState(false);
  
  const [requestSubmittedFlag, setRequestSubmittedFlag] = useState(false);
  const [otpResendRequest, setOtpResendRequest] = useState({});
  const [isCurrentKeyAccordionOpen, setIsCurrentKeyAccordionOpen] = useState(false);  
  const [isApiPropertiesAccordionOpen, setIsApiPropertiesAccordionOpen] = useState(false);
  const [isMemberAccordionOpen, setIsMemberAccordionOpen] = useState(false);

  const [newCredentialsCreated, setNewCredentialsCreated] = useState(false);
  const [isCredentialAccordionOpen, setIsCredentialAccordionOpen] = useState(false); 
  const [disableAccordion, setDisableAccordion] = useState(false);
  const [credentialEnvForRemoveAllAccess, setCredentialEnvForRemoveAllAccess] = useState("");
  const [testCredentials, setTestCredentials] = useState([]);
  const [productionCredentials, setProductionCredentials] = useState([]);
  
  const credentialViewRef = useRef(null);
  const apiPropertyViewRef = useRef(null);
  const memberViewRef = useRef(null);
  const confirmButtonRef = useRef(null);
  //writing this to open the accordion on creation of new credentials

  const [viewCredentialResendReq, setViewCredentialResendReq] = useState(
    {
      action: "",
      credential: "",
      environment: "",
      created: "",
    }
  );
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        if (removeAllAccessConfirmationModal && confirmButtonRef.current) {
          confirmButtonRef.current.click();
        } 
      }
    };

    if (removeAllAccessConfirmationModal) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [removeAllAccessConfirmationModal]);

  useEffect(() => {
    if (newCredentialsCreated) {
        setIsCurrentKeyAccordionOpen(true);
    }
}, [newCredentialsCreated]);
useEffect(() => {
  // This is to scroll down to credential view before unsubscribing API
  if (scrollToCredentialView) {
    setIsCredentialAccordionOpen(true);

    // Wait for the accordion to open before scrolling into view
    setTimeout(() => {
      if (credentialViewRef.current) {
        credentialViewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300); 

    setScrollToCredentialView(false);
  }
  else if (scrollToApiAndPropertyView) {
    setIsApiPropertiesAccordionOpen(true);

    // Wait for the accordion to open before scrolling into view
    setTimeout(() => {
      if (apiPropertyViewRef.current) {
        apiPropertyViewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300); 

    setScrollToApiAndPropertyView(false);
  }
  else if (scrollToMemberView) {
    setScrollToMemberView(true);

    // Wait for the accordion to open before scrolling into view
    setTimeout(() => {
      if (memberViewRef.current) {
        memberViewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300); 

    setScrollToMemberView(false);
  }
}, [scrollToCredentialView,scrollToApiAndPropertyView,scrollToMemberView]);




  const [isProdPropActive, setIsProdPropActive] = useState(false);
  const [isProdCredActive, setIsProdCredActive] = useState(false);
  useEffect(() => {
    UserService.getuserDetails().then((response) => {
      setUserDetails(response.data);
      console.log(response.data);
      console.log("userDetails inside project details==>" + response.data.id);
    }).catch(error => {
      console.log(error);
    });
  }, [])

  function getUserId() {
    const cookies = new Cookies();
    return cookies.get("cookieNameCurrentUser");
  }
  const handleOtp = (OtpDataToProject) => {
    setOtpDataFromModal(OtpDataToProject);
  }

  const resendOtp = async() => {
    if(otpInputModelOpenendForAction==="PROJECT_PROD_ACCESS")
    await getXCode(otpInputModelOpenendForAction, 'POST', null);
    else if(otpInputModelOpenendForAction==="view")
    viewCredentialData(viewCredentialResendReq.action, viewCredentialResendReq.credential, viewCredentialResendReq.environment, viewCredentialResendReq.created)
  }

  const displayToasterMessage = async (toastName, Msg) => {
    if (toastName === "success") {
      toast.success(Msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });
    }
    else if (toastName === "error") {
      toast.error(Msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });

    }
  }
  const handleDeleteProject = async (projectMode) => {
    // alert("you clicked on delete project"+projectMode)
    if (await confirm("Confirmation Required","You are about to delete this project.")) {
      try {

        if (projectMode === "Sandbox") {
          const response = await ProjectsService.deleteSbxProjectByProjectId(projectId);
          if (response) {
            console.log(response.message)
            console.log(response.data);
            toast.success("Project Deleted Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 8000, //6 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              transition: Slide
            });

            setTimeout(() => {
              window.location.replace("/projects/")
            }, 4000);


          }

        }
        else {
          const response = await ProjectsService.deleteCompanyProjectByProjectId(companyId, projectId);
          if (response) {
            console.log(response.message)
            console.log(response.data);
            toast.success("Project Deleted Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 8000, //6 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              transition: Slide
            });

            setTimeout(() => {
              window.location.replace("/projects/")
            }, 4000);
          }
        }





      } catch (error) {

        console.error(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 8000, //6 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide
        });
        

      }

    } else {

    }
  }





  const handleUndoIncludedApi = (record) => {
    const rSubscriptions = apiSubscriptionsRemovedId.filter((subscription) => subscription !== record.id);
    setApiSubscriptionsRemovedId(rSubscriptions);
    if (rSubscriptions.length === 0) {
      
      setSaveDiscardDisabled(true);
      
      setComputedProperties([]);
    }
    else {

      const subscriptionsData = projectsDashboard.apiSubscriptions
        .filter((ss) => !rSubscriptions.includes(ss.id))
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            environment: item.environment,
            testUrl: item.testUrl
          };
        }
        )

      
      propComputedObj(projectId, subscriptionsData);
    }
  }
  const handleDeletedIncludedApi = (ID) => {

    const subscriptions = (apiSubscriptionsRemovedId) => [
      ...apiSubscriptionsRemovedId,
      ID

    ];
    setApiSubscriptionsRemovedId(subscriptions);
    const subscriptionsData = projectsDashboard.apiSubscriptions.filter((ss) =>
      ![...apiSubscriptionsRemovedId, ID].includes(ss.id))
    
    // call API with data
    if (subscriptionsData.length === 0) {
      setComputedProperties(projectProp);
      setSaveDiscardDisabled(false);
    }
    else {
      const subscriptionsData = projectsDashboard.apiSubscriptions
        .filter((ss) => ![...apiSubscriptionsRemovedId, ID].includes(ss.id))
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            environment: item.environment,
            testUrl: item.testUrl
          };
        }
        )

     
      propComputedObj(projectId, subscriptionsData);
    }

  }


  const deleteProjectMember = async (projectId, memberId) => {
    try {
      console.log('deleteProjectMember');
      const confirmationCode = await getXCode('DEL_P_MEMBER', 'DELETE', projectId, memberId);
      ProjectsService.deleteProjectMember(projectId, memberId, confirmationCode).then((response) => {

        toast.success("Member Deleted Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 8000, //6 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);

        console.log(response.data);
      }).catch(error => {
        console.log(error);
      })
    } catch (error) {
      console.error(error);
    }
  }

  const propComputedObj = async (projectId, data) => {

    try {
      console.log('allEditedProperties and subscriptions', data);
      const confirmationCode = await getXCode('C_PROP', 'POST', projectId);
      const obj = await ProjectsService.computedProperties(projectId, data, confirmationCode);
      setComputedPropertiesRes(obj.data);
      console.log(obj.data);
      const comput = getDifferentObjects(projectProp, obj.data);
      setComputedProperties(comput);
      setSaveDiscardDisabled(false);
    } catch (error) {
      console.error(error);
      setSaveDiscardDisabled(true);
    }

  }
  function getDifferentObjects(allProps, computedP) {
    return allProps.filter(ap => {
      return !computedP.some(cp => {
        return (ap.id === cp.id && ap.environment === cp.environment);
      });
    });
  }



  const handleEdit = (id, valueData, env) => {
    setEditID(id);
    setPropValue(valueData);
    setPropEnv(env);
  }

  const handleUpdate = (record) => {
    const editData = projectProp.map((item) => ((item.id === editId && item.environment === propEnv) && propValue ? { ...item, ['value']: propValue } : item))
    setProjectProp(editData);
    prepareEditProperties(editId, propValue, propEnv);
    handleEdit(-1, '', '');
    setSaveDiscardDisabled(false);
    setIsHighlighted(true);
   
  }

  const handleUndoEditedProperty = (record) => {
    const oldValue = projectsDashboard.projectProperties.filter(item => item.id === record.id && item.environment === record.environment)
      .map(e => { return e.value; });
    const editData = projectProp.map((item) => ((item.id === record.id && item.environment === record.environment) && oldValue ? { ...item, ['value']: oldValue } : item))
    setProjectProp(editData);
    editedProperties.map((item, index) => {
      if ((item.id === record.id) && (item.environment === record.environment)) {
        removeItem(index);
      }
    });
    if (editedProperties.length === 1) {
      setSaveDiscardDisabled(true);
    }
  }

  const requestProductionAccess = async () => {
    var title = "Confirmation For Requesting Production Access";
    var message = "Please check all properties & values before promoting to production.";
    if (await confirm(title, message)) {
      otpInputModelOpenendForAction = 'PROJECT_PROD_ACCESS';
      await getXCode(otpInputModelOpenendForAction, 'POST', null);
    } 
  }

  const otpInputModelOpenendForProjectProdAccess = async (OtpDataFromModal) => {
    if(OtpDataFromModal.length!==0){  
      console.log(OtpDataFromModal)
     
      ServiceRequestModel.companyId=companyId;
      ServiceRequestModel.projectId=projectId;
      ServiceRequestModel.requestType="PromoteToProduction";
      console.log(ServiceRequestModel);
       const response = await SRQService.createNewServiceRequest(ServiceRequestModel,OtpDataFromModal)
          .then((response) => {
            setResponseMessage(response.data.message);
            setMyVerticallyCenteredModal(true);
      setOtpModalOpen(false);
          }).catch(error =>{
            toast.error(error.response.data.message, {

              position: toast.POSITION.BOTTOM_RIGHT,
    
              autoClose: 8000, //6 seconds
    
              hideProgressBar: false,
    
              closeOnClick: true,
    
              pauseOnHover: true,
    
              draggable: true,
    
              transition: Slide
    
            });
            console.log(error);
          });
    
     }
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        backdrop='static' 
        keyboard="False"
        dialogClassName='my-modal'
      >
        <Modal.Header className='modal-header'>
          <Modal.Title id="contained-modal-title-vcenter">
          Confirmation Of Service Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <p>
         {responseMessage}</p>
        </Modal.Body>
        <Modal.Footer>
        <a className="button comm-button-style primary" style={{textAlign:'center'}} href="/viewserviceRequests">OK</a>
        </Modal.Footer>
      </Modal>
    );
  }
  function RemoveAllAccessConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        backdrop='static' 
        keyboard="False"
        dialogClassName='removeAllAccessConfirmationModal'
      >
        <Modal.Header className='modal-header'>
          <Modal.Title id="contained-modal-title-vcenter">
          Confirmation Required
          </Modal.Title>
          <div className="col-sm d-flex justify-content-end">
            <a href='javascript:;' className="pop-close-wrap mpa-button-cancel bi bi-x-lg"  onClick={() => setRemoveAllAccessConfirmationModal(false)}/>
        </div>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <p>
          You are about to remove all test credentials. Your development and test systems will lose access to these APIs until you generate new credentials.</p>
        </Modal.Body>
        <Modal.Footer>
        <a className="button comm-button-style primary rmvAllAccessCnf" style={{textAlign:'center'}} onClick={() => {handleConfirm(); setRmvAllAccessConfirmHighlighted(true) }  }ref={confirmButtonRef}>CONFIRM</a>
        <a className="button comm-button-style secondary rmvAllAccessCancel" style={{textAlign:'center'}} onClick={() =>setRemoveAllAccessConfirmationModal(false)}>CANCEL</a>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleConfirm = async () => {
    console.log("inside handle confirm and cred env is "+credentialEnvForRemoveAllAccess)
    setRemoveAllAccessConfirmationModal(false)
    await removeAndRemoveAllAccess("removeAllAccess", null, credentialEnvForRemoveAllAccess);
  };
  const handlePropDiscardChanges = async () => {
    if (await confirm("Confirmation Required", "Do you wish to discard your changes?")) {
      setSaveDiscardDisabled(true);
      setProjectProp(projectsDashboard.projectProperties);
      setEditedProperties([]);
      setComputedProperties([]);
      setApiSubscriptionsRemovedId([]);
      setComputedPropertiesRes([]);
    } else {
      
    }


  }

  const removeItem = (index) => { setEditedProperties(editedProperties.filter((o, i) => index !== i)); };

  function prepareEditProperties(eid, newValue, env) {
    editedProperties.map((item, index) => {
      if ((item.id === eid) && (item.environment === propEnv)) {
        removeItem(index);
      }
    });
    const eProp = (editedProperties) => [
      ...editedProperties,
      {
        id: eid,
        value: newValue,
        environment: env
      }
    ]
    setEditedProperties(eProp);
  }

  const CurvedArrowRight = (props) => (
    <svg
      width="10vw"  
      height="auto"
      viewBox="0 0 190 150"
      fill="none"
      style={{ 
        position: 'absolute', 
        right: '3vw',  
        top: '15%',   
        transform: 'translateY(-50%)',  
        pointerEvents: 'none'
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* Curved Path */}
      <path
        d="M20 147 C44 185, 124 167, 136 86"
        stroke="#2d32aa"
        strokeWidth="6"
        fill="none"
        strokeLinecap="round"
      />
      
      {/* Arrowhead */}
      <path
        d="M125 83 L140 76 L145 94 L130 86 Z"
        fill="#2d32aa"
      />
    </svg>
);


  const checkdeletedAPICredential = (deletedIdList) => {
    let isAvailable = false;
    if (
      apiSubscriptionsRemovedId != null &&
      apiSubscriptionsRemovedId.length > 0
    ) {
      console.log("------APIID List----" + JSON.stringify(deletedIdList));
      const subNameList = projectsDashboard.apiSubscriptions
        .filter((obj) => deletedIdList.includes(obj.id))
        .map((item) => item.name);
      console.log("------APINAME List----" + JSON.stringify(subNameList));
      projectsDashboard.credentials.map((cred) => {
        cred.credentials.map((subCred) => {
          const auxJSON = JSON.parse(subCred.auxData);
          const filteredObject = auxJSON.relatedAPIs.filter((api) =>
            subNameList.includes(api.apiName)
          );
          if (filteredObject != null && filteredObject.length >= 1)
            isAvailable = true;
        });
      });
    }
    return isAvailable;
  };

  const handleSubmit = async () => {
    let isdeletedAPI = checkdeletedAPICredential(apiSubscriptionsRemovedId);
    if (isdeletedAPI) {
      setInfoModelOpen(true);
    } else {
      callApiAndProperties();
    }
  };

  const callApiAndProperties = async () => {
    try {
      let apiRequestObject = '';
      if (apiSubscriptionsRemovedId.length != 0) {
        const subscriptions = apiSubscriptionsIds.filter((apiID) =>
          !apiSubscriptionsRemovedId.includes(apiID.id));

        //alert(JSON.stringify(subscriptions));
        let dataRequest = [];
        if (subscriptions.length != 0) {
          dataRequest = computedPropertiesRes.map((e) => {
            if (editedProperties.some((e2) => e2.id === e.id && e2.environment === e.environment)) {
              const eFiltered = editedProperties.filter((ef) => ef.id === e.id && ef.environment === e.environment);
              e.value = eFiltered[0].value;
              return { id: e.id, value: e.value, environment: e.environment };
            }
            return { id: e.id, value: e.value, environment: e.environment };
          });
        }
        apiRequestObject = { "projectProperties": dataRequest, "apiSubscriptions": subscriptions };
      } else {
        apiRequestObject = { "projectProperties": editedProperties, "apiSubscriptions": apiSubscriptionsIds };
      }

      console.log('allEditedProperties and subscriptions', apiRequestObject);
      
      const apisAndPropXCode = await getXCode('API_PROP', 'PATCH', projectId);
      const response = await ProjectsService.updateEditedProperties(projectId, apiRequestObject, apisAndPropXCode);
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }
  var _creatingCredMessage =
    "Taking time, please try to view credential after sometime...";
  var _credPremissions = {
    Production: {
      create: false,
      view: false,
      remove: false,
    },
    Test: {
      create: false,
      view: false,
      remove: false,
    },
    Sandbox: {
      create: false,
      view: false,
      remove: false,
    },
  };
  //create production credentials
  const createPRDCredential = async (action, state) => {
    if (state === "Production") {
      //for creating production credentials
      //alert("you are creating production credentials!!!")
      _credPremissions.Production.create =
        projectsDashboard.userCan_PRD_createCredentials;
      _credPremissions.Production.view = projectsDashboard.userCan_PRD_viewCredentials;
      _credPremissions.Production.remove =
        projectsDashboard.userCan_PRD_removeCredentials;
      //check if user can create production credentials
      if (_credPremissions.Production.create) {
        //generate scc , send otp sms to phone
        try {
          const response = await invokeSCC("POST", projectId, action, state, null, null);
          if (response.data.channel === 'SMS') {
            otpInputModelOpenendForAction = "create";
            setOtpModalOpen(true);
          }
          console.log(response.data);

        } catch (error) {
          console.log(error);
        }



      }
    }

  }



  const createTSTOrSBXCredential = async (action, state) => {
    try {
      setSpinnerActiveCreateCredential(true);
      const response = await invokeSCC("POST", projectId, action, state, null, null);
      if (response.data.channel === 'NO_CHECK') {
        const createCredResponse = await ProjectsService.createNewProjectCredential(projectId, state, response.data.code);
  
        // Validate response structure
        if (!createCredResponse || !createCredResponse.data) {
          console.error("Invalid response from createNewProjectCredential:", createCredResponse);
          return;
        }
  
        const credentialsData = createCredResponse.data;
        
          setNewCredentialsCreated(true);
     
  
        // Validate credentials array
        if (!Array.isArray(credentialsData)) {
          console.error("Unexpected credentials data structure:", credentialsData);
          return;
        }
  
        console.log("Credential created, updating UI with 'Request Submitted' status.");
  
        // Update UI with "Request Submitted" status
        updateProjectDashboardData({ credentials: credentialsData });
  
        // Fetch and update the dashboard data after 3 seconds
        setTimeout(() => {
          invokeDashboardApi();
        }, 3000);

        setTimeout(() => {
          setNewCredentialsCreated(false);
      }, 1000);
      }
    } catch (error) {
      console.error("Error creating credential:", error);
    }
  };
  
  
  
  
  
  
  const createCredential = async (action, state) => {
    
    switch (state) {
      case 'Test':
        return createTSTOrSBXCredential(action, state);
      case 'Sandbox':
        return createTSTOrSBXCredential(action, state);
      case 'Production':
        return createPRDCredential(action, state);
      default:
        return null
    }

  }

  const invokeSCC = async (method, projectId, action, mode, createdDate, credentialId) => {
    try {
      const authURL = '/projects/'.concat(projectId).concat('/credentials/').concat(credentialId);
    
      const response = await ProjectsService.getStrongConfirmationCode(method, projectId, action, mode, createdDate, credentialId)
      

      console.log("response from scc service in invokescc method:::" + response.data)
      return response;

    } catch (error) {
      console.log(error)
    }
  }

  



  
  const invokeDashboardApi = () => {
    let _dashboardAPICounterForCredStatus = 0;
    const _maxDashboardAPICounterForCredStatus = 3;
    const _timeoutDashboardAPICredCreation = 6000;
    const _creatingCredMessage = "Taking time, please try to view credential after sometime...";
  
    const statusMessages = {
      submitted: "Request submitted for creating credentials",
      creating: "Creating credentials...",
      created: "Credentials can be viewed for ",
      failed: "Credential creation failed for all Apis"
    };
  
    const displayStatusMessage = (status, extraMessage = "") => {
      if (statusMessages[status]) {
       if (status==="failed")
        displayToasterMessage("error", statusMessages[status] + extraMessage);
        else displayToasterMessage("success", statusMessages[status] + extraMessage);
      }
    };
  
    const fetchDashboardData = async () => {
      if (_dashboardAPICounterForCredStatus === 0) {
        displayStatusMessage("submitted");
      }
  
      try {
        const response = await ProjectsService.getProjectsDashboardByProjectId(projectId);
        let response1 = response.data;
  
        console.log("Dashboard data response:", response1);
  
        // Update project dashboard data
        updateProjectDashboardData(response1);
  
        let allCredentialsChecked = true;
        let createdAPIs = [];
  
        if (response1.credentials && Array.isArray(response1.credentials)) {
          response1.credentials.filter(envStatusObj => envStatusObj.status === "Current").forEach(envStatusObj => {
            if (Array.isArray(envStatusObj.credentials)) {
              envStatusObj.credentials.forEach(credential => {
                if (credential.gwcStatus === "Creating" || credential.gwcStatus === null) {
                  allCredentialsChecked = false;
                } else if (credential.gwcStatus === "Created") {
                  try {
                    const relatedAPIs = JSON.parse(credential.auxData).relatedAPIs;
                    relatedAPIs.forEach(api => {
                      createdAPIs.push(`${api.apiName}`);
                    });
                  } catch (error) {
                    console.error("Error parsing auxData:", error);
                  }
                }
              });
            }
          });
        }
  
        if (allCredentialsChecked) {
          _dashboardAPICounterForCredStatus = 0; 
          if (createdAPIs.length > 0) {
            displayStatusMessage("created", `${createdAPIs.join(", ")}`);
          } else {
            displayStatusMessage("failed");
          }
        } else {
          _dashboardAPICounterForCredStatus++;
          if (_dashboardAPICounterForCredStatus <= _maxDashboardAPICounterForCredStatus) {
            setTimeout(fetchDashboardData, _timeoutDashboardAPICredCreation);
          } else {
            displayToasterMessage("error", _creatingCredMessage);
            _dashboardAPICounterForCredStatus = 0; 
          }
        }
      } catch (error) {
        console.error(error);
        if (_dashboardAPICounterForCredStatus >= _maxDashboardAPICounterForCredStatus) {
          displayToasterMessage("error", _creatingCredMessage);
          _dashboardAPICounterForCredStatus = 0; 
        } else {
          setTimeout(fetchDashboardData, _timeoutDashboardAPICredCreation);
        }
      }
    };
  
    fetchDashboardData();
  };
  
  
  
  
  
  
  
  
  
  
  





  async function otpInputModelOpenendForCreateCred(OtpDataFromModal) {
    try {
      //alert("verifyOtpforCreateCtedential"+OtpDataFromModal);
      const response = await ProjectsService.createNewProjectCredential(projectId, "Production", OtpDataFromModal);
      if (response) {
        setOtpModalOpen(false);
        // toast.success(" credentials created successfully", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        //   autoClose: 8000, //6 seconds
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   transition: Slide
        // });
        invokeDashboardApi();
        //window.location.reload();
      }
    } catch (error) {
      setOtpModalOpen(true);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide

      });
    }


  }
  async function otpInputModelOpenendForRemoveCred(OtpDataFromModal) {

    try {
      
      const response = await ProjectsService.removeProjectCredential(projectId, credentialRecord.environment, OtpDataFromModal, credentialRecord.created)
      if (response) {

        displayToasterMessage("success", "The identified credentials have been deleted")
        setOtpModalOpen(false);
        window.location.reload();
       
      }
    } catch (error) {
      setOtpModalOpen(true);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });
      //alert("error");
      //this.setState({ message: error.message });
    }

  }
  async function otpInputModelOpenendForRemoveAllCred(OtpDataFromModal) {

    try {
      
      const response = await ProjectsService.removeAllProjectCredential(projectId, "Production", OtpDataFromModal)
      if (response) {
        setOtpModalOpen(false);
        toast.success("All credentials removed successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 8000, //6 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide
        });
        window.location.reload();
        
      }
    } catch (error) {
      setOtpModalOpen(true);

      displayToasterMessage("error", error.response.data.message);

      
    }

  }
  async function otpInputModelOpenendForViewCred(OtpDataFromModal) {
    let tokenUrl = viewCredentialsDataForOTP.tokenEndpointUrl;
    let consumerkey = viewCredentialsDataForOTP.consumerkey;
    let secret = viewCredentialsDataForOTP.secret;

    
       
  let _tokenEndpointArray = tokenUrl.split(";");
  
  
  if (_tokenEndpointArray.length > 1) {
 
    tokenUrl = _tokenEndpointArray.shift();
  }
    
    try {

      const tokenResponse = await ProjectsService.postWithBasicAuth(tokenUrl, consumerkey, secret, {});

      let viewURL = viewCredentialsDataForOTP.credentialUrl;
      let accessToken = tokenResponse.data.access_token;
      console.log('accessToken-------' + accessToken);


      let viewCredentialURL = viewURL + '/user/' + userDetails.id + '/credentials/' + viewCredentialId;
      const viewResponse = await ProjectsService.getViewCredentials(viewCredentialURL, accessToken, OtpDataFromModal)
      //alert(tokenResponse.data);
      setOtpModalOpen(false);
      console.log("consumerkey view------" + viewResponse.data.consumerkey);
      console.log("secret view------" + viewResponse.data.secret);
      let VIEW_DATA = "consumerkey : " + viewResponse.data.consumerkey + " || " + "secret : " + viewResponse.data.secret;
      //alert(VIEW_DATA);
      setViewCredentialPopupData({
        key: viewResponse.data.consumerkey,
        secret: viewResponse.data.secret
      })
      setViewCredentialPopup(true);
    } catch (error) {
      console.log(error)
      setOtpModalOpen(true);
      displayToasterMessage("error", error.response.data.message)
    }

  }
  //after otp punching in production environment,this method is called
  async function verifyOtp(OtpDataFromModal) {

    if (OtpDataFromModal.length != 0) {

      console.log(OtpDataFromModal)
      OtpDataFromModal = Number(OtpDataFromModal.join(''));
      switch (otpInputModelOpenendForAction) {
        case 'create':
          return otpInputModelOpenendForCreateCred(OtpDataFromModal);
        case 'view':
          return otpInputModelOpenendForViewCred(OtpDataFromModal);
        case 'delete':
          return otpInputModelOpenendForRemoveCred(OtpDataFromModal);
        case 'deleteAll':
          return otpInputModelOpenendForRemoveAllCred(OtpDataFromModal);
        case 'PROJECT_PROD_ACCESS':
          return otpInputModelOpenendForProjectProdAccess(OtpDataFromModal);
        default:
          return null
      }

    }

  }



  const getXCode = async (action, httpVerbData, ...param) => {
    const response = await ProjectsService.getXStrongConfirmationCode(action, httpVerbData, ...param);
    const code = response.data.code;
    console.log("confirmation code:" + code);
    if (response && response.data.channel === "NO_CHECK") { return code; }
    else if (response && response.data.channel === "SMS") { 
      setOtpModalOpen(true);
      return code; 
    }
    else {
      let errorMessage = 'unhandled X-code channel exception';
      alert(errorMessage);
      throw new Error(errorMessage);
    }
  }


  const addNewAPIAndProperties = async (apiId, env, url) => {
    try {
      const subNameList = projectsDashboard.apiSubscriptions.filter((obj) => obj.id === apiId).map((item) => item.name);
      let newSubscription = { 'id': apiId, 'name': subNameList[0], 'environment': env, 'testUrl': url }
      let alteredNewSubscription = [...projectsDashboard.apiSubscriptions, newSubscription];
      const confirmationCode = await getXCode('C_PROP', 'POST', projectId);
      const projectPropertiesObj = await ProjectsService.computedProperties(projectId, alteredNewSubscription, confirmationCode);
      const subscriptions = alteredNewSubscription.map((item) => { return { id: item.id }; })
      let dataRequest = projectPropertiesObj.data.map((e) => { return { id: e.id, value: e.value, environment: e.environment }; })
      let apiRequestObject = { "projectProperties": dataRequest, "apiSubscriptions": subscriptions };
      console.log('addNewAPIAndProperties', apiRequestObject);
      const apisAndPropXCode = await getXCode('API_PROP', 'PATCH', projectId);
      const response = await ProjectsService.updateEditedProperties(projectId, apiRequestObject, apisAndPropXCode);
      setAPIDilogOpen(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }

  }



  
  const updateProjectDashboardData = (projectsDashboardLocal) => {
    setSpinnerActiveCreateCredential(false);
        
    
    if (!projectsDashboardLocal || !projectsDashboardLocal.credentials) {
      console.error("Invalid projectsDashboardLocal data:", projectsDashboardLocal);
      return;
    }
  
    // Ensure all credentials have gwcStatus set to "Request Submitted" if it's null
    const updatedCredentials = projectsDashboardLocal.credentials.map(envStatusObj => {
      return {
        ...envStatusObj,
        credentials: envStatusObj.credentials.map(credential => ({
          ...credential,
          gwcStatus: credential.gwcStatus === null ? "Request Submitted" : credential.gwcStatus
        }))
      };
    });
  
    // Group and merge credentials by environment, status, and created
    const result = updatedCredentials.reduce((acc, obj) => {
      const key = `${obj.environment}_${obj.status}_${obj.created}`;
      const existingItem = acc.find((item) => item.key === key);
  
      if (existingItem) {
        existingItem.credentials.push(...obj.credentials);
      } else {
        acc.push({
          key,
          environment: obj.environment,
          status: obj.status,
          created: obj.created,
          credentials: [...obj.credentials],
        });
      }
      return acc;
    }, []).map(({ key, ...rest }) => rest);
  
    // Set the final result to state
    const finalResult = { ...projectsDashboardLocal, credentials: result };
    console.log("Final dashboard data being set:", finalResult);
    setProjectsDashboard(finalResult);
    // Reset newCredentialsCreated after a short delay to allow re-triggering on subsequent additions
   
  };
  
  
  
  
  
  

  useEffect(() => {
    setNewCredentialsCreated(false);
    ProjectsService.getProjectsDashboardByProjectId(projectId).then((response) => {
      console.log("proj prop::::::")
      console.log(response.data.projectProperties);
      setProjectProp(response.data.projectProperties);
      const subscriptions = response.data.apiSubscriptions.map((item) => {
        return { id: item.id };
      }
      )
      setApiSubscriptionsIds(subscriptions);
      
      updateProjectDashboardData(response.data);
      setProjectEnvironment(response.data.mode.mode);
    setTestCredentials(response.data.credentials.filter(cred => cred.environment === 'Test'));
    setProductionCredentials(response.data.credentials.filter(cred => cred.environment === 'Production'))

    }).catch(error => {
      console.log(error);
    });

    AppCache.getSingleCacheData('SSUI', 'userDetails').then((userData) => {
      
    }).catch(error => {
      console.log(error);
    });

  }, []);

 




  function privateTabAncor() {
    setIsProdPropActive(false);
  }
  function privateTabButton() {
    
  }

  function sharedTabAncor() {
    setIsProdPropActive(true);
  }

  function addNewProjectButton() {
    
  }
  function manageUserButton() {
    
  }
  return (
    <div>

      <div className="row" style={{ minHeight: '60px'}} >
        <div className="col-8 c-blue mt-2 ms-3 mb-2">
         
            <h1 className='mpa-project-header-company mt-2'>{projectsDashboard.companyName && projectsDashboard.companyName.toUpperCase()}</h1>
            <h2 className='mpa-project-header'>{projectsDashboard.projectName && projectsDashboard.projectName.toUpperCase()}</h2>
          
        </div>
        {projectsDashboard.mode && infoAndProdButton(projectsDashboard.mode.mode)}
      </div>
      <div className={projectsDashboard.mode && getBgCls(projectsDashboard.mode.mode)} style={{ minHeight: '25px' }} >
        <div className="col-10">
          <h6 className="mt-2 ms-2">
            {projectsDashboard.mode && projectsDashboard.mode.mode.toUpperCase()}
          </h6></div>
        <div className="col-2">
          <Link onClick={() => handleDeleteProject(projectsDashboard.mode.mode)} className="float-end mt-2 me-4">
            <h5 className="bi bi-trash text-white" />
          </Link>
        </div>
      </div>

      <div className="container-fluid" >
        <div className="row mt-3 mb-5">
          <div className="col-2 fixed-position">
            <ul className="api-navigation   project-font-style ms-4">
              <li className="mt-0 row">
                {/* <Link className="col-10 c-blue" onClick={() => navigate(-1)}><b>All Projects</b></Link> */}
                <a className="col-10 c-blue " href="/projects">
                  
                <i className="ps-2 project-arrow-style col-2 bi bi-arrow-left c-blue "></i>
                  <b>All Projects</b></a>
              </li>
              <li className="mt-4 c-blue"><a className="c-blue" href="#headingApisAndProp" data-bs-toggle="collapse" onClick={()=> setScrollToApiAndPropertyView(true)} data-bs-target="#collapseApisAndProp">APIs and Properties</a></li>
              <li className="mt-2"><a className="c-blue" href="#headingCredentials" data-bs-toggle="collapse" onClick={()=>setScrollToCredentialView(true)} data-bs-target="#collapseCredentials">Credentials</a></li>
              <li className="mt-2"><a className="c-blue" data-bs-toggle="collapse" data-bs-target="#collapseMember" onClick={()=>setScrollToMemberView(true)} href="#headingMember">Members</a></li>
            </ul>

          </div>
          <div className="col-10" style={{ backgroundColor: 'white' }}>
            <div className="accordion" id="projectDetailsAccordion">
              <div className="accordion-item mt-4 border-0">
              <div className="accordion-header" ref={apiPropertyViewRef} id="headingApisAndProp" style={{ borderBottom: '1px solid rgb(45, 50, 170)' }}>
                  <div className="row">
                    <div className="col-11 d-flex justify-content-left text-center " >
                      <span style={{ fontWeight: 'bold' }} className="p-2 ml-5 c-blue"><h5>APIS AND PROPERTIES</h5></span>

                    </div>
                    <div className="col-1 d-flex justify-content-end">

                      <div className="row">
                        <div className="col-6 d-flex justify-content-end p-0 ">
                          {projectsDashboard && projectsDashboard.userCanModifyApiList === true ?

                            <button type="button" className='openModalBtn' onClick={() => { setAPIDilogOpen(true) }} data-bs-toggle='tooltip' data-bs-placement="top" title={saveDiscardDisabled ? 'Add API' : 'Save or Discard the edited changes to enable add API button.'} style={{ border: 'none', backgroundColor: 'white' }} disabled={!saveDiscardDisabled}>
                              {/* <img src={plusIcon} alt="Add API" width={40} height={40} /> */}
                              <h1 style={{color:'#2d32aa'}} width={40} height={40}>+</h1> 
                           
                            </button>
                            : <></>
                          }
                        </div>
                        <div className="col-6 d-flex justify-content-end p-0 ">

                          <button className="accordion-button collapsed commonMargin" type="button" data-bs-toggle="collapse" data-bs-target="#collapseApisAndProp" aria-expanded="false" aria-controls="collapseApisAndProp">
                          </button>

                        </div>
                      </div>


                    </div>
                  </div>
                  <div id="collapseApisAndProp" className="accordion-collapse collapse" aria-labelledby="headingApisAndProp" data-bs-parent="" style={{ borderTop: '1px solid skyblue' }}>
                  { projectsDashboard.apiSubscriptions && projectsDashboard.apiSubscriptions.length!==0 ?
                  (  
                    <div className="accordion-body">
                      <div className='row mb-3 mt-3'>
                        <span style={{ fontWeight: 'bold' }} className="c-blue p-2 ml-5"><h6>INCLUDED APIS</h6></span>
                      </div>
                      <div className='row ms-0 ps-0'>

                        <table class="table c-blue">
                          <thead>
                            <tr >
                              <td>NAME</td>
                              <td><span className="text-center">API VERSION</span></td>
                              <td><span className="text-center">ENDPOINTS</span></td>
                              <td><span className="text-center">DOCUMENTATION</span></td>
                              <td><span className="text-center">ACTION</span></td>
                            </tr>
                          </thead>

                          <tbody style={{ backgroundColor: '#c9e9fb' }}>
                            {/* here we are checking the todo state and if it is not empty then we will apply the map() method to it. */}
                            {projectsDashboard.apiSubscriptions && projectsDashboard.apiSubscriptions.map((record) => {
                              return (
                                <tr id={record.id} style={{ backgroundColor: isIdPresentInArrayJson(apiSubscriptionsRemovedId, record.id) === true ? '#ffe0e0' : '#c9e9fb' }}>
                                  <td><span>{record.name}</span></td>
                                  <td><span className="text-center">{record.apiVersion}</span></td>
                                  <td >
                                    <OverlayTrigger 
                                    placement="top"
                                    delay={{ show: 250, hide:5555}} 
                                    overlay={popover(projectsDashboard.mode.mode,record.testUrl,record.productionUrl,record.sandboxUrl)}
                                    trigger="hover"
                                    rootClose
                                    show={currentPopover == `${record.id}`}
                                    onToggle={() => {
                                        if( currentPopover == `${record.id}` )
                                            setCurrentPopover(null)
                                        else
                                            setCurrentPopover(`${record.id}`)
                                    }}                                    
                                    >
                                      
                                      <a  className="text-center">
                                        <h5 className="bi bi-diagram-3" ></h5>
                                      </a>
                                    </OverlayTrigger>

                                  </td>
                                  <td >
                                    <a href={record.specificationUrl} className="text-center" target="_blank" >
                                      <h5 className="bi bi-credit-card-2-front" ></h5>
                                    </a>
                                  </td>
                                  <td >


                                    {isIdPresentInArrayJson(apiSubscriptionsRemovedId, record.id) === true ?
                                      <Link onClick={() => handleUndoIncludedApi(record)} className="text-center" >
                                        <h5 className="bi bi-arrow-clockwise" />
                                      </Link>
                                      :
                                      <Link onClick={() => handleDeletedIncludedApi(record.id)} className="text-center">
                                        <h5 className="bi bi-trash" />
                                      </Link>
                                    }


                                  </td>
                                </tr>);
                            })}


                          </tbody>


                        </table>

                      </div>
                      <div className="row mt-3 c-blue"><h6>PROPERTIES</h6></div>


                      {projectsDashboard.mode && projectsDashboard.mode.mode === "Sandbox"
                        ?
                        corporateSandboxProperties()
                        :
                        corporateProjectTabularProperties()
                      }


                    </div>
                  ):
                  <div className="arrowContainer">
                     <CurvedArrowRight width="120" height="120"/>
                    {/* <i className="bi bi-arrow-up-short rotate-90"></i> */}
                  <p  className='noSelectedApiText ml-10'> You have not selected any APIs. Click on the + to select one.</p>
                  </div>
                  }
                  </div>
                </div>
              </div>

              <div className="accordion-item mt-4 border-0">
                <div ref={credentialViewRef} className="accordion-header" id="headingCredentials" style={{ borderBottom: '1px solid rgb(45, 50, 170)' }}>
                  <div className="row">
                    <div  className="col-11 d-flex justify-content-left text-center ">
                      <span style={{ fontWeight: 'bold' }} className="c-blue p-2 ml-2"><h5>CREDENTIALS</h5></span>

                    </div>
                    <div class="col-1 d-flex justify-content-end">
                      <div class="row">
                        <div class="col-6 d-flex justify-content-end p-0 ">
                          {projectsDashboard.mode && projectsDashboard.mode.mode === "Sandbox" ?
                            <button type="button" style={{ border: 'none', backgroundColor: 'white' }} onClick={() => { { createCredential("create", "Sandbox") } }}>
                                {/* <img src={plusIcon} alt="Manage sandbox project" width={40} height={40} /> */}
                                <h1 style={{color:'#2d32aa'}} width={40} height={40}>+</h1> 
                              </button> : <></>
                          }
                        </div>
                        <div class="col-6 d-flex justify-content-end p-0 ">
                          <button 
                          className="accordion-button collapsed commonMargin credentialAccordBtn" 
                          type="button" data-bs-toggle="collapse" data-bs-target="#collapseCredentials" 
                          aria-expanded={isCredentialAccordionOpen? "true":"false"}  
                          disabled={apiSubscriptionsIds.length===0}
                          onClick={()=> 
                          
                            setIsCredentialAccordionOpen(!isCredentialAccordionOpen)
                           
                          }
                          aria-controls="collapseCredentials">
                          
                          
                          </button>
                        </div>
                      </div>
                    </div>



                  </div>

                  <div id="collapseCredentials" className={`accordion-collapse collapse ${isCredentialAccordionOpen ? "show" : ""}`} aria-labelledby="headingCredentials" data-bs-parent="" style={{ borderTop: '1px solid skyblue' }}>
                    <div className="container-fluid">
                      {projectEnvironment && projectEnvironment === "Sandbox"
                        ?
                        currentAndEndOfLifeCredentials("Sandbox")
                        :
                        corporateCredentials()
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item mt-4 border-0" id="headingMember">
                <div ref={memberViewRef} className="accordion-header" id="headingMember" style={{ borderBottom: '1px solid rgb(45, 50, 170)' }}>
                  <div className="row">
                    <div className="col-11 d-flex justify-content-left text-center ">
                      <span style={{ fontWeight: 'bold' }} className="c-blue p-2 ml-2"><h5>MEMBERS</h5></span>

                    </div>
                    <div className="col-1 d-flex justify-content-end">
                      <div className="row">
                        <div className="col-6 d-flex justify-content-end p-0 ">

                          <button type="button" className='openModalBtn' onClick={() => { setMemberModalOpen(true) }} data-bs-toggle='tooltip' data-bs-placement="top" title='Add New Member' style={{ border: 'none', backgroundColor: 'white' }}>
                            {/* <img src={plusIcon} alt="Add Member" width={40} height={40} /> */}
                            <h1 style={{color:'#2d32aa'}} width={40} height={40}>+</h1> 
                          </button>

                        </div>
                        <div className="col-6 d-flex justify-content-end p-0 ">

                          <button className="accordion-button collapsed commonMargin" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMember" aria-expanded="false" aria-controls="collapseMember">
                          </button>

                        </div>
                      </div>

                    </div>
                  </div>

                  <div id="collapseMember" className="accordion-collapse collapse" aria-labelledby="headingMember" data-bs-parent="" style={{ borderTop: '1px solid skyblue' }}>
                  {projectsDashboard.projectMembers && projectsDashboard.projectMembers.length!==0 ?
                  (
                    <div className="accordion-body">
                      <table class="table c-blue">
                        <thead>
                          <tr >
                            <td>NAME</td>
                            <td>Email</td>
                            <td>INVITED BY</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: '#c9e9fb' }}>
                          {projectsDashboard.projectMembers && projectsDashboard.projectMembers
                            //.filter(record => record.status==="EndOfLife" && record.environment==="Test")
                            .map((record) => (

                              <tr>
                                <td><span>{record.name}</span></td>
                                <td>{record.email}</td>
                                <td >{record.invitedBy}</td>
                                <td >
                                  {projectsDashboard.userCanRemoveMember == true ?
                                    <a href="#" className="text-center" onClick={() => deleteProjectMember(projectId, record.id)}>
                                      <h5 className="bi bi-trash" ></h5>
                                    </a>
                                    : <></>}

                                </td>
                              </tr>

                            ))}



                        </tbody>
                      </table>
                    </div> 
  )
                    : 
                    
                    <div className="arrowContainer">
                    <CurvedArrowRight 
                      width="120" 
                      height="120" 
                    />
                    <p className="noAddedMemberText ml-0">Add Your Team</p>
                  </div>
}
                  </div>
                </div>
              </div>

            </div>





          </div>
        </div>

      </div>
      <MyVerticallyCenteredModal
        show={myVerticallyCenteredModal}
        onHide={() => setMyVerticallyCenteredModal(false)}
      />
      <RemoveAllAccessConfirmationModal
        show={removeAllAccessConfirmationModal}
        onHide={() => setRemoveAllAccessConfirmationModal(false)}
      />
      <div className="toast-container"><ToastContainer limit={8} /></div>
      {infoModelOpen && <InformationDialog modalHeading={"Changes To APIs"} setInfoModelOpen={setInfoModelOpen} displayMessage={"Before unsubscribing these APIs. Please remove all the credentials created for these subscribed APIs"} setScrollToCredentialView={setScrollToCredentialView}/>}


      {apiDialogOpen && <AddAPIDialog ModalHeading={"ADD API"} setAPIDilogOpen={setAPIDilogOpen} addNewAPIAndProperties={addNewAPIAndProperties} env={projectsDashboard.mode && projectsDashboard.mode.mode} />}

      {memberModalOpen && <MembersModal ModalHeading={"abc"} setMemberModalOpen={setMemberModalOpen} />}
      {OtpModalOpen && <OtpInputModal ModalHeading={"abc"} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal} verifyOtp={verifyOtp} credentialRecord={credentialRecord} otpInputModelOpenendForAction={otpInputModelOpenendForAction} otpResendRequest={otpResendRequest} resendOtp={resendOtp}/>}

      {uploadPropertiesOpenModal && <UploadProperties ModalHeading={"UPLOAD FILE"} propValueSet={setPropValue} updateHandlerProps={handleUpdate} setOpenModal={setUploadPropertiesOpenModal} />}
      {viewCredentialPopup && <ViewCredentialPopup setViewCredentialPopup={setViewCredentialPopup} ModalHeading={'View Credentials'} popupFor={"cView"} credentialData={viewCredentialPopupData} />}
      {prodAccessInfoModelOpen && <ProdAccessInfoDialog setInfoModelOpen={setProdAccessInfoModelOpen} />}

    </div>

  )

  function corporateProjectTabularProperties() {
    return (
      <div className=" ">
        {/* Nav pills */}
        <ul className="nav nav-pills justify-content-center row" role="tablist">
          <li className={`nav-item col-6 tabular-button row ${!isProdPropActive?'activeNav':''}`} id="pNav">
            <div className="col-10 text-center">
              <a id="privateTabAncor" className=" nav-link  active pb-2 tb" data-bs-toggle="pill" href="#testProperties" onClick={() => privateTabAncor()}>
                <b>TEST PROPERTIES</b>
              </a>
            </div>
            <div className="col-1 text-center opacity-50" >
              <h6><button id="privateTabButton" type="button" style={{ border: 'none', backgroundColor: 'white' }} onClick={() => privateTabButton()}>

              </button></h6>
            </div>
          </li>
          <li className={`nav-item col-6 tabular-button row ${isProdPropActive?'activeNav':''}`} id="sNav">
            <div className="col text-center" style={{ padding: '0px' }}>
              <a id="sharedTabAncor" className="nav-link pb-2 tb" data-bs-toggle="pill" href="#prodProperties" onClick={() => sharedTabAncor()}>
                <b>PRODUCTION PROPERTIES</b>
              </a>
            </div>
          </li>
        </ul>
        {/* Tab panes */}
        <div className="tab-content">
          {renderTableForProperties("Test", "tab-pane active", "testProperties")}
          {renderTableForProperties("Production", "tab-pane fade", "prodProperties")}
        </div>

      </div>
    )
  }

  function renderTableForProperties(env, pDivClass, pDivId) {
    return (
      <div id={pDivId} className={pDivClass}>
  {projectProp && projectProp.length > 0 && (
   
    (projectProp.some(record => record.environment === 'Test') || projectProp.some(record => record.environment === 'Production')) ? (
      <table className="table c-blue">
       
        {projectProp.filter(record => record.environment === env).length > 0 && (
          <thead>
            <tr>
              <td>ID</td>
              <td>PROPERTIES</td>
              <td>VALUES</td>
              <td></td>
            </tr>
          </thead>
        )}

       
        {projectProp.filter(record => record.environment === env).length > 0 ? (
          <tbody style={{ backgroundColor: '#c9e9fb' }}>

            {projectProp
              .filter(record => record.environment === env)
              .map((record, index) => {
                if (record.id === editId) {
                  return (
                    <tr key={index} style={{ backgroundColor: '#c7daf7' }}>
                      <td>{record.id}</td>
                      <td>
                        {record.name}
                        {record.required ? <b>*</b> : null}
                        {record.ownedBy === 'Nets' && (
                          <i className="bi bi-lock-fill ms-1" style={{ fontSize: '13px', verticalAlign: 'bottom' }}></i>
                        )}
                      </td>
                      <td>
                        <input
                          placeholder=""
                          style={propInputBottomBorder}
                          type="text"
                          defaultValue={propValue}
                          onChange={e => setPropValue(e.target.value)}
                        />
                      </td>
                      <td>
                        {record.ownedBy !== 'Nets' && (
                          <div className="d-flex justify-content-center">
                            <Link onClick={() => handleUpdate(record)} className="text-center">
                              <h5 className="bi bi-save" />
                            </Link>
                            <Link
                              className="text-center openModalBtn"
                              type="button"
                              onClick={() => {
                                setUploadPropertiesOpenModal(true);
                                handleEdit(record.id, record.value, record.environment);
                              }}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Upload file"
                            >
                              <h5 className="bi bi-cloud-upload"></h5>
                            </Link>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                } else if (isRecordPresentInPropertiesCustomCollection(computedProperties, record.environment, record.id)) {
                  return (
                    <tr key={index} style={{ backgroundColor: '#ffe0e0' }}>
                      <td>{record.id}</td>
                      <td>
                        {record.name}
                        {record.required ? <b>*</b> : null}
                        {record.ownedBy === 'Nets' && (
                          <i className="bi bi-lock-fill ms-1" style={{ fontSize: '13px', verticalAlign: 'bottom' }}></i>
                        )}
                      </td>
                      <td>
                        <input
                          className="c-blue"
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                          value={record.value}
                          disabled
                        />
                      </td>
                      <td></td>
                    </tr>
                  );
                } else {
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: recordPresentInEditProperties(env, record.id) ? '#c7daf7' : '#c9e9fb'
                      }}
                    >
                      <td>{record.id}</td>
                      <td>
                        {record.name}
                        {record.required ? <b>*</b> : null}
                        {record.ownedBy === 'Nets' && (
                          <i className="bi bi-lock-fill ms-1" style={{ fontSize: '13px', verticalAlign: 'bottom' }}></i>
                        )}
                      </td>
                      <td>
                        <input
                          className="c-blue"
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                          value={record.value}
                          disabled
                        />
                      </td>
                      <td>
                        {record.ownedBy !== 'Nets' && (
                          <div className="d-flex justify-content-center">
                            <Link
                              onClick={() => handleUndoEditedProperty(record)}
                              className="text-center"
                            >
                              <h5 className="bi bi-arrow-clockwise" />
                            </Link>
                            <Link
                              onClick={() => handleEdit(record.id, record.value, record.environment)}
                              className="text-center"
                            >
                              <h5 className="bi bi-pencil" />
                            </Link>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        ) : null}
      </table>
    ) : null
  )}

  {/* Render save and discard buttons */}
  <div className="apim-pop-button-w mt-3 mb-3">
    <button
      type="button"
      className={!saveDiscardDisabled ? 'highlighted comm-button-style' : 'btn btn-primary comm-button-style'}
      ref={saveChangesButtonRef}
      onClick={() => handleSubmit()}
      disabled={saveDiscardDisabled}
      id="saveChangesButton"
    >
      SAVE CHANGES
    </button>
    <button
      type="button"
      className="btn btn-primary comm-button-style"
      onClick={() => handlePropDiscardChanges()}
      disabled={saveDiscardDisabled}
    >
      DISCARD CHANGES
    </button>
  </div>
</div>



    
    

    )
   
  }


  function corporateSandboxProperties() {
    return (
      <div className=" ">

        <div className="tab-content">
          {renderTableForProperties("Sandbox", "tab-pane active", "SandBoxProperties")}
        </div>

      </div>
    )
  }
  function recordPresentInEditProperties(env, id) {
    if (editedProperties.length != 0) {
      return editedProperties.some(element => {
        if (element.environment === env && element.id === id) {

          return true;
        }
        return false;
      });
    } else return false;
  }

  function isRecordPresentInPropertiesCustomCollection(collection, env, id) {
    if (collection.length != 0) {
      return collection.some(element => {
        if (element.environment === env && element.id === id) {

          return true;
        }
        return false;
      });
    } else return false;
  }

  function isIdPresentInArrayJson(collection, id) {
    if (collection.length != 0) {
      return collection.some(element => {
        if (element === id) { return true; }
        return false;
      });
    } else return false;
  }


  function corporateCredentials() {
    return (
      <div >
        {/* Nav pills */}
        <ul className="nav nav-pills justify-content-center row mt-3" role="tablist">
          <li className={`nav-item col-6 tabular-button row ${!isProdCredActive?'activeNav':''}`} id="pNav">
            <div className="col-10 text-center">
              <a id="privateTabAncor" className=" nav-link  active tb pb-2" data-bs-toggle="pill" href="#testCredentials" onClick={() => setIsProdCredActive(false)}>
                <b>TEST CREDENTIALS</b>
              </a>
            </div>
            <div className="col-1 text-center opacity-50" >
              {apiSubscriptionsIds.length!==0?
              <button id="privateTabButton" type="button" style={{ border: 'none', backgroundColor: 'white' }} onClick={() => createCredential("create", "Test")}>
                {/* <img src={plusIcon} alt="Manage Test project" width={30} height={30} /> */}
                <h4 style={{color:'#2d32aa'}} width={30} height={30}>+</h4> 
              </button> : <></>}
            </div>
          </li>
          <li className={`nav-item col-6 tabular-button row ${isProdCredActive?'activeNav':''}`} id="sNav">
            <div className="col text-center" style={{ padding: '0px' }}>
              <a id="sharedTabAncor" className="nav-link tb pb-2" data-bs-toggle="pill" href="#prodCredentials" onClick={() => setIsProdCredActive(true)}>
                <b>PRODUCTION CREDENTIALS</b>
              </a>
            </div>
            <div className="col-1 text-center opacity-50" >
            {projectsDashboard && projectsDashboard.mode && projectsDashboard.mode.mode === "Production"?
              <button id="privateTabButton" type="button" style={{ border: 'none', backgroundColor: 'white' }} onClick={() => createCredential("create", "Production")}>
                <h4 style={{color:'#2d32aa'}} width={30} height={30}>+</h4> 
                {/* <img src={plusIcon} alt="add production credentials" width={30} height={30} /> */}
              </button>
              : <></>}
            </div>
          </li>
        </ul>
        {/* Tab panes */}
        <div className="tab-content">
          <div id="testCredentials" className="tab-pane active">
            {currentAndEndOfLifeCredentials("Test")}
          </div>
          <div id="prodCredentials" className="tab-pane fade">
            {currentAndEndOfLifeCredentials("Production")}
          </div>
        </div>
      </div>
    )
  }

  function currentAndEndOfLifeCredentials(credentialEnv) {
    return (
      <div className="accordion" id="accordionExample1">
        {projectsDashboard.credentials && projectsDashboard.credentials
          .filter(record => record.environment === credentialEnv)
          .sort((first, second) => {
            const dateA = new Date(first.created);
            const dateB = new Date(second.created);
            return dateB - dateA;
          })

          .map((record, index) => {
            if (record.status === "Current") { return apiKeysCurrent(record, index) }
            else if (record.status === "EndOfLife") { return apiKeysEndOfLife(record, index) }
            else { }
          })}
        {projectsDashboard.credentials?.length !== 0 ? (
    <div className="apim-pop-button-w mt-3 mb-3">
        {(
            credentialEnv === "Sandbox" ||
            (projectsDashboard.mode &&
                projectsDashboard.mode.mode === "Test" &&
                credentialEnv === "Test" &&
                testCredentials?.length !== 0) ||
            (projectsDashboard.mode &&
                projectsDashboard.mode.mode === "Production" &&
                (
                    (credentialEnv === "Test" && testCredentials?.length !== 0) ||
                    (credentialEnv === "Production" && productionCredentials?.length !== 0)
                ))
        ) ? (
            <button
                type="button"
                className="btn btn-primary comm-button-style"
                disabled={removeAllAccessConfirmationModal}
                onClick={() => {
                    setRemoveAllAccessConfirmationModal(true);
                    setCredentialEnvForRemoveAllAccess(credentialEnv);
                }}
            >
                REMOVE ALL ACCESS
            </button>
        ) : (
            credentialEnv === "Production" ? (
                <p className="custom-message">No credentials are associated with the project</p>
            ) : (
                <></>
            )
        )}
    </div>
) : (
  <div className="arrowContainer">
    {projectsDashboard.apiSubscriptions.length!==0 && credentialEnv === "Test"?
  <CurvedArrowRight width="120" height="120" style={{ 
        position: 'absolute', 
        right: '42vw',  // Use viewport width units for consistent right margin
        top: '15%',    // Place the arrow slightly downward
        transform: 'translateY(-50%)',  // Keeps it centered vertically
        pointerEvents:"none"
      }}/> : <></> }
    
 {/* <i className="bi bi-arrow-up-short rotate-90"></i> */}
 {credentialEnv === "Test" ?(
  <p  className={projectsDashboard.apiSubscriptions.length!==0?'noCreatedAPICredentialText ml-0':"createdAPICredentialText"}> 
  {credentialEnv === "Test" 
                  ? (projectsDashboard.apiSubscriptions.length!==0 ?"Click on the + to create credentials":
                   <p>No credentials are associated with the project</p>)
                   : <p className='createdAPICredentialText'>No credentials are associated with the project</p>
                  
                  //projectsDashboard.apiSubscriptions
              }
  
  </p>
 ) : <><p className='createdAPICredentialText'>No credentials are associated with the project</p></>
            }
 

</div>
    
)}

      </div>
    )
  }




  function apiKeysCurrent(record, index) {
    return (
      <div className="accordion-item" style={{ border: 'none' }}>
        {spinnerActiveCreateCredential?
           (
            <div className="accordion-item mt-3" style={{ border: 'none' }} >
                    <Spinner animation="border" variant="primary" style={{ display: 'block', width: 17, height: 17, marginLeft: 500, marginTop:2 }} />
              </div>
           )
          : (<>
              
          </>)
        
        }
    <div className="accordion-header mt-2 row ms-0" id="headingKeysCurrent" style={{ color: '#FFF', backgroundColor: '#2d32aa' }}>
        <div className="col-4 d-flex p-3">API Key status: {record.status}</div>
        <div className="col-6 d-flex p-3">Keys created {formatDateForCredentials(record.created)}</div>
        <div className="col-2 row d-flex p-0">
            <div className="col-8 text-center"></div>
            <div className="col-4 text-center mt-1 pr-0">
                <button
                    className="accordion-button api-keys-wrap-current-white collapsed"
                    id='apiKeysCurrent' 
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseKeysCurrent"
                    aria-expanded={isCurrentKeyAccordionOpen ? "true" : "false"}
                    aria-controls="collapseKeysCurrent"
                    onClick={() => setIsCurrentKeyAccordionOpen(!isCurrentKeyAccordionOpen)}
                    
                >
                </button>
            </div>
        </div>
    </div>

    <div id="collapseKeysCurrent"  className={`accordion-collapse collapse ${isCurrentKeyAccordionOpen ? "show" : ""}`} aria-labelledby="headingKeysCurrent" data-bs-parent="#accordionExample1">
        <table id={record.created} className="table c-blue mt-1">
            <thead className="api-key-color-blue mb-2">
                <tr>
                    <td>Gateway</td>
                    <td>APIs on gateway</td>
                    <td>API keys for gateway</td>
                    <td></td>
                </tr>
            </thead>
            <tbody style={{ backgroundColor: '#c9e9fb' }}>
                {record.credentials.map((credential, index) => {
                    const auxJSON = JSON.parse(credential.auxData);
                    let tokenEndpoint ='';
                    let resourceName='';
                    let _tokenEndpointArray = credential.tokenEndpoint.split(";");
                    if (_tokenEndpointArray.length === 1) {
                      // If there's only one element, set it as tokenEndpoint
                      tokenEndpoint = _tokenEndpointArray[0];
                    } else if (_tokenEndpointArray.length > 1) {
                      // If there are more than one, set the second element as tokenEndpoint
                      tokenEndpoint = _tokenEndpointArray[1];
                    }
                  
                  
                    if (_tokenEndpointArray.length > 2) {
                      // Display the third string in the array as resourceName
                      resourceName = _tokenEndpointArray[2]; 
                    }
                    return (
                        <tr key={index} className="align-middle" style={{ borderColor: 'white', borderTopWidth: '4px' }}>
                            <td>
                                <span className="gatway-group-name"> Name: {credential.tokenEpgName}</span>
                                <span className="gateway-token-name" style={{fontSize:'13px'}}>
                                Token endpoint: {tokenEndpoint}
                                {resourceName && (
                                <>
                                <br />
                                    Resource: {resourceName}
                                </>
                                 )}
                                </span>
                                <span className="gatway-token-name">Status: {credential.gwcStatus == null ? "Request Submitted" : credential.gwcStatus}</span>
                            </td>
                            <td>
                                {auxJSON && auxJSON.relatedAPIs.map((api, i) => (
                                    <p key={i}>{api.apiName} - {api.version}</p>
                                ))}
                            </td>
                            <td><h4>**** ***** *****</h4></td>
                            <td>
                            {credential.gwcStatus === 'Created' ? (
    <>
        {(spinnerActiveIndex !== credential.id + index) ? (
            <Link className="text-center" onClick={() => {
              setViewCredentialResendReq(
                {
                  action: "view",
                  credential: credential,
                  environment: record.environment,
                  created: record.created,
                }
              );
                viewCredentialData("view", credential, record.environment, record.created);
                setSpinnerActiveIndex(credential.id + index);
            }}>
                <h5 className="bi bi-eye-fill" />
            </Link>
        ) : (
            <Spinner animation="border" variant="primary" style={{ display: 'block', width: 15, height: 15, marginLeft: 10 }} />
        )}
    </>
) : credential.gwcStatus === 'CreationFailed' ? null : (
    <Spinner animation="border" variant="primary" style={{ display: 'block', width: 15, height: 15, marginLeft: 10 }} />
)}

                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
</div>


    )


  }



  function getBgCls(environment) {
    const env = environment.toUpperCase();
    if (env === 'PRODUCTION')
      return 'row bg_prod m-0';
    else if (env === 'SANDBOX')
      return 'row bg_sandbox m-0';
    else
      return 'row bg_test m-0';
  }


  function infoAndProdButton(env) {
    if (projectsDashboard.userCanPromoteProject === true)
      return (
        <>
          <div className="col-4 text-center row pe-0">
            <div className='col-1'></div>
            <button type="button" className="btn btn-primary comm-button-style col-9" onClick={() => requestProductionAccess()}><h6 className="mb-1">REQUEST PRODUCTION ACCESS</h6></button>
            <div className="col-1 text-start ps-0 mt-2 ps-3">
          <Link onClick={() => setProdAccessInfoModelOpen(true)}>
              <h5 className="bi bi-info-circle" ></h5>
            </Link>
          </div>
          </div>
          
        </>
      )
  }


  function apiKeysEndOfLife(record, index) {

    
    return (
      <div className="accordion-item" style={{ border: 'none' }}>






        <div className="accordion-header mt-2 row ms-0" id={index} style={{ color: '#797979', backgroundColor: '#D8D8D8' }}>
          <div className="col-4 d-flex p-3">API Key status: {record.status}</div>
          <div className="col-6 d-flex p-3">Keys created {formatDateForCredentials(record.created)}</div>

          <div className="col-2 row d-flex p-0">


            <div className=" col-8 text-center">
              <Link className="text-center mt-1" onClick={() => { removeAndRemoveAllAccess("removeAccess", record, null) }}>
                <h5 className="bi bi-trash text-end" />
              </Link>
            </div>
            <div className="col-4 text-center mt-1 pr-0" >
              <button className="accordion-button api-keys-wrap-endOffLife collapsed" style={{ color: '#797979', backgroundColor: '#D8D8D8' }} type="button" data-bs-toggle="collapse" data-bs-target={"#ed" + index} aria-expanded="false" aria-controls={"ed" + index}>

              </button>
            </div>

          </div>
        </div>







        <div id={"ed" + index} className="accordion-collapse collapse" aria-labelledby={index} data-bs-parent="#accordionExample1">



          <table class="table" id={index}>
            <thead className=" mb-2" style={{ color: '#000000', backgroundColor: '#C1C0C0' }}>
              <tr >
                <td>Gateway</td>
                <td>APIs on gateway</td>
                <td>API keys for gateway</td>
                <td></td>
              </tr>
            </thead>
            <tbody style={{ color: '#797979', backgroundColor: '#D8D8D8' }}>


              {record.credentials.map((credential, index) => {
                const auxJSON = JSON.parse(credential.auxData);
                return (
                  <tr className="align-middle mt-2" style={{ borderTopWidth: '4px' }}>
                    <td>
                      <span className="gatway-group-name"> Name: {credential.tokenEpgName}</span>
                      <span className="gatway-token-name">Token endpoint: {credential.tokenEndpoint}</span>
                      <span className="gatway-token-name" >Status: {requestSubmittedFlag?"Request Submitted":credential.gwcStatus} </span>

                    </td>
                    <td>
                      {auxJSON.relatedAPIs.map(api => {
                        return (<p>{api.apiName} - {api.version}</p>)
                      })}
                    </td>
                    <td><h4>**** ***** *****</h4></td>
                    <td>
                    {credential.gwcStatus==='Created'?
                    <>
{(spinnerActiveIndex!=(credential.id + index))?
<Link className="text-center" onClick={() =>
  { 
    setViewCredentialResendReq(
      {
        action: "view",
        credential: credential,
        environment: record.environment,
        created: record.created,
      }
    );
    viewCredentialData("view", credential, record.environment, record.created)
  setSpinnerActiveIndex(credential.id + index);
}
  
  }>
                          <h5 className="bi bi-eye-fill" />
                        </Link>:
<Spinner animation="border" variant="primary"  style={{ display: 'block',  width: 15, height: 15 , marginLeft: 10}} /> 
                      }
                    </>:<></>}
                      

                    </td>
                  </tr>

                );
              })}

            </tbody>
          </table>



        </div>




      </div>


    )

  }

  async function removeAccess(action, record, credentialsTabState) {
   
    if (record.environment === "Test" || record.environment === "Sandbox") {
        try {
            const response = await invokeSCC("DELETE", projectId, action, record.environment, record.created, null);
            if (response && response.data.channel === "NO_CHECK") {
                const code = response.data.code;
                console.log("confirmation code:" + code);
                try {
                    const removeAllCredentialsResponse = await ProjectsService.removeProjectCredential(projectId, record.environment, code, record.created);
                    if (removeAllCredentialsResponse) {
                      displayToasterMessage("success", "The identified credentials have been deleted")
                      setTimeout(() => {
                        

                        window.location.reload();
                    }, 2000);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    } else if (record.environment === "Production") {
        const response = await invokeSCC("DELETE", projectId, action, record.environment, record.created, null);
        if (response && response.data.channel === "SMS") {
            otpInputModelOpenendForAction = "delete";
            credentialRecord = record;
            setOtpModalOpen(true);
        }
    }
}

async function removeAllAccess(action, record, credentialsTabState) {
  console.log("inside remove all access")
  if (credentialsTabState === "Test" || credentialsTabState === "Sandbox") {
      try {
          const response = await invokeSCC("DELETE", projectId, action, credentialsTabState, null, null);
          if (response && response.data.channel === "NO_CHECK") {
              
              const code = response.data.code;
              console.log("confirmation code:" + code);
              try {
                  const removeAllProjectCredentialResponse = await ProjectsService.removeAllProjectCredential(projectId, credentialsTabState, code);
                  if (removeAllProjectCredentialResponse) {
                    displayToasterMessage("success", "The identified credentials have been deleted")
                    setRemoveAllAccessConfirmationModal(false);
                      setTimeout(() => {
                        setProjectsDashboard({ ...projectsDashboard, credentials: [] });
                      }, 2000);
                  }
              } catch (error) {
                  console.log(error);
              }
          }
      } catch (error) {
          console.log(error);
      }
  } else if (credentialsTabState === "Production") {
      try {
          const response = await invokeSCC("DELETE", projectId, action, credentialsTabState, null, null);
          if (response && response.data.channel === "SMS") {
              otpInputModelOpenendForAction = "deleteAll";
              setOtpModalOpen(true);
          }
      } catch (error) {
          console.log(error);
      }
  }
}

  

  async function removeAndRemoveAllAccess(action, record, credentialsTabState) {
   
    if (action === "removeAccess") {
        if (record) {
            await removeAccess(action, record, credentialsTabState);
        } else {
            console.error("Record is required for removeAccess action");
        }
    } else if (action === "removeAllAccess") {
        await removeAllAccess(action, record, credentialsTabState);
    }
}

  async function viewCredentialData(action, credential, environment, created) {
    //alert("you clicked on view credentials for project ==>"+credential.id);
    console.log("RECORD ID FOR VIEW PURPOSE===>" + credential.id)
    if (action === "view") {
      //check environment of credentials to ve viewed
      if (environment === "Test" || environment === "Sandbox" || environment === "Production") {
        try {
          const response = await invokeSCC("GET", projectId, action, environment, created, credential.id);
          const code = response.data.code;
          console.log("confirmation code:" + code);

          if (response && response.data.channel === "BROWSER") {
            const selfServicePPGwcResponse = await ProjectsService.getSecretDetailsForCred(projectId, credential.id, response.data.code);
            if (selfServicePPGwcResponse) {
              
              setViewCredentialsDataForOTP(selfServicePPGwcResponse.data);
              setViewCredentialId(credential.id);
            }

            otpInputModelOpenendForAction = "view";
            setOtpModalOpen(true);
            setSpinnerActiveIndex(-1);
             


          }
        } catch (error) {
          console.log(error);
        }

      }

    }
  }



}







export default ProjectDetails;